// /* You can add global styles to this file, and also import other style files */
@use "variables.scss" as *;

.filter-container {
  margin-right: var(--content-padding);
  margin-bottom: var(--content-padding);
  padding: var(--content-padding);
  background-color: var(--base-bg);
  .filter-header {
      @include header();
  }
  flex: 0 0 auto;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  overflow: auto;
}
// //@import '@angular/material/prebuilt-themes/indigo-pink.css';
// //@import url('../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css');
// //@import url('../../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css');
// //@import url('../../node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css');
// //@import url('../../node_modules/devexpress-dashboard/dist/css/dx-dashboard.darkmoon.compact.css');

// .dx-state-disabled.dx-widget {
//   opacity: 1;
// }

// .entete {
//   margin: 10px;

//   .action-buttons {
//     display: flex;

//     h2 {
//       margin-top: 0;
//     }

//     .align-right-buttons {
//       margin-left: auto;
//       margin-right: 5%;
//       min-width: 200px;
//       display: flex;
//       justify-content: space-around;
//     }
//   }

//   .row-info {
//     display: flex;
//     > span {
//       flex: 1;
//     }

//     .codeBo {
//       margin-top: -10px;
//     }
//   }
// }

// // Popup qui affiche le code barre
// .barcode {
//   text-align: center;
// }

// #gridContainer {
//   height: calc(100vh - 240px);
// }

// .mt-40px {
//   margin-top: 40px;
// }

// .no-padding-important {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
// }

// // Affiche le petit icon indiquant l'ouvertue du lien dans un nouvel onglet, au survole (on hover)
// a[target='_blank']:hover::after {
//   content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
//   margin: 0 3px 0 5px;
// }

dx-scroll-view .dx-scrollable-content {
  height: 100%;
  .dx-scrollview-content {
    height: 100%;
  }
}